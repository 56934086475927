module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flowby","short_name":"Flowby","start_url":"/","background_color":"#ffffff","theme_color":"#008573","display":"minimal-ui","icon":"static/favicon.svg","icons":[{"src":"/favicons/web-app-manifest-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/web-app-manifest-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"af7d18396bf09ed2c9f79d5d09266ce9"},
    },{
      plugin: require('../../../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
